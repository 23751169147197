<template>
    <div class="menu fixed flex month-floating-menu">
        <MonthPicker @monthChange="$emit('monthChange', $event)" />
        <SettingsShowHideButton @changeCalendarSettings="$emit('changeCalendarSettings', $event)" />
        <div>
            <p
                class="flex justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
                text-white shadow-md transition hover:bg-green-500 cursor-pointer"
                @click="$emit('goToPrevMonth')"
            >
                <fa-icon v-if="$wait.is('loading.prev')" :icon="['fad','spinner']" spin class="text-xl" />
                <svg
                    v-else
                    width="24"
                    height="24"
                    style="margin-left:-2px;"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                        fill="currentColor"
                    />
                </svg>
            </p>
        </div>
        <div>
            <p
                class="flex justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
                text-white shadow-md transition hover:bg-green-500 cursor-pointer"
                @click="$emit('goToNextMonth')"
            >
                <fa-icon v-if="$wait.is('loading.next')" :icon="['fad','spinner']" spin class="text-xl" />
                <svg
                    v-else
                    width="24"
                    height="24"
                    style="margin-left:-2px;"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z" fill="currentColor" />
                </svg>
            </p>
        </div>
        <div>
            <p
                class="flex justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
                text-white shadow-md transition hover:bg-green-500 cursor-pointer"
                @click="$emit('goToToday')"
            >
                <fa-icon v-if="$wait.is('loading.home')" :icon="['fad','spinner']" spin class="text-xl" />
                <svg v-else width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21 8.77217L14.0208 1.79299C12.8492 0.621414 10.9497 0.621413 9.77817 1.79299L3 8.57116V23.0858H10V17.0858C10 15.9812
                        10.8954 15.0858 12 15.0858C13.1046 15.0858 14 15.9812 14 17.0858V23.0858H21V8.77217ZM11.1924 3.2072L5
                        9.39959V21.0858H8V17.0858C8 14.8767 9.79086 13.0858 12 13.0858C14.2091 13.0858 16 14.8767 16
                        17.0858V21.0858H19V9.6006L12.6066 3.2072C12.2161 2.81668 11.5829 2.81668 11.1924 3.2072Z"
                        fill="currentColor"
                    />
                </svg>
            </p>
        </div>
        <BackToTop />
    </div>
</template>
<script>
export default {
    components: {
        SettingsShowHideButton:
            () => import(/* webpackChunkName: "MonthBasic/SettingsShowHideButton" */ './components/SettingsShowHideButton'),
        BackToTop:   () => import(/* webpackChunkName: "BackToTop" */ '@/components/BackToTop'),
        MonthPicker:
            () => import(/* webpackChunkName: "MonthBasic/SettingsShowHideButton" */ './components/MonthPicker'),
    },
};
</script>
<style scoped>
    .menu {
        bottom: 10px;
        right: 65px;
        z-index: 200;
    }
</style>
